<template>
  <div>
    <div style="margin: 10px 10px 0 0; border: 0px solid red">
      <form-create
        :rule="mergedRule"
        :option="option"
        @submit="onSubmit"
      ></form-create>
    </div>
    <!-- 在这里添加一个宽度约为 100px 的空白 -->
    <div style="height: 100px; background-color: transparent"></div>
  </div>
</template>

<script>
import api from '@/api/process'
import { MessageBox } from 'element-ui'

export default {
  name: 'showUploadFile',
  data() {
    return {
      templateType: 1,
      processId: 0,
      taskId: 0,
      // 全局配置
      option: {},
      // 人员 表单
      rankRule: [],
      fileIdList: [], // 文件id数据
    }
  },

  created() {
    this.templateType = this.$route.params.templateType
    this.processId = this.$route.params.processId
    this.taskId = this.$route.params.taskId
    this.fetchData()
  },
  computed: {
    mergedRule() {
      return [...this.rankRule]
    },
  },

  methods: {
    async fetchData() {
      // 等待文件上传的组件
      await this.addFileUploadField()
    },

    // // 添加 文件上传的组件
    async addFileUploadField() {
      let token = window.localStorage.getItem('token') || ''

      const fileUploadField = {
        type: 'upload',
        field: 'file', // 字段名
        title: '附件上传',
        props: {
          multiple: false, // 是否支持多文件上传
          maxSize: 30 * 1024 * 1024, // 最大文件大小 (30MB)
          previewImage: false, // 是否预览
          // accept: '.pdf,.doc,.docx,.xlsx,.txt,.png,.jpg,.ppt,.pptx',
          // 修改后的 accept 属性
          accept: '*/*',
          // 移动端适配
          capture: null,
          webkitdirectory: false,
          beforeRemove: (file, fileList) => {
            // 获取当前文件在 fileList 中的下标
            let index = fileList.findIndex((f) => f.uid === file.uid) // 假设你用 uid 作为标识符

            // 获取对应的文件名
            let fileName = file.name

            return new Promise((resolve, reject) => {
              MessageBox.confirm(
                `确定要删除文件 "${fileName}" 吗？`,
                '确认删除',
                {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
                }
              )
                .then(() => {
                  api.deleteFileById(this.fileIdList[index])
                  this.fileIdList.splice(index, 1) // 删除对应的文件ID
                  resolve() // 返回 true，表示删除文件
                })
                .catch(() => {
                  // 用户点击取消，不删
                  reject() // 返回 false，表示取消删除
                })
            })
          },

          name: 'file', // 后端接收的字段名

          action: '/prod-api/admin/upload/img', // 上传接口
          onSuccess: (response, file, fileList) => {
            if (response.code === 200) {
              // 提取返回的数据
              const fileId = response.data.id
              // 将文件ID和文件名添加到对应的列表中
              this.fileIdList.push(fileId)
              this.$message.success(`文件上传成功`)
            } else {
              this.$message.error('文件上传失败，请重试！')
            }
          },
          headers: {
            Token: `${token}`, // 如果需要认证，传递 Token
          },
        },
        validate: [{ required: false, message: '请上传文件' }],
      }

      const fileUploadDescription = {
        type: 'vanNoticeBar', // 定义为文本组件
        props: {
          text: '上传类型：ppt、work文档、excel、图片，上传大小限制30MB',
        },
      }

      // 将上传字段和说明文本一起加入 rankRule
      this.rankRule.push(fileUploadDescription)

      this.rankRule.push(fileUploadField)
    },

    onSubmit() {
      console.log('fileIdList:', this.fileIdList)
      let processFormVo = {
        processId: this.processId,
        templateType: this.templateType,
        fileIdList: this.fileIdList,
      }
      api.uploadFileByRecord(processFormVo).then((response) => {
        //调整到已发起列表
        this.$message.success('修改成功')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-form {
  .el-form-item {
    /deep/ .el-form-item__label {
      font-size: 18px;
      font-weight: 800;
      color: blue;
    }
  }
}

.group-title {
  font-size: 16px;
  font-weight: bold;
  color: #606266;
  margin: 12px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #ebeef5;
}
</style>
